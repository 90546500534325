<script setup lang="ts">
import PageFooter from '@components/PageFooter/PageFooter.vue';

export interface Props {
  isBgBlue?: boolean;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isBgBlue: false,
  title: '',
});

</script>

<template>
  <Head>
    <Title>Mogelijk - {{ props.title }}</Title>
    <Link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicons/apple-touch-icon.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
    <Link rel="manifest" href="/favicons/site.webmanifest" />
    <Meta name="msapplication-TileColor" content="#da532c" />
    <Meta name="theme-color" content="#ffffff" />
  </Head>
  <main id="mogelijk__ui-library" class="min-h-screen w-full flex flex-col" :class="{'bg-white': !props.isBgBlue}">
    <header class="w-full flex justify-center p-6">
      <div class="base:flex tablet:block w-fit justify-center">
        <img
          src="/android-chrome-192x192.png"
          alt="mogelijk vastgoed"
          class="w-8 tablet:w-12"
        />
        <img
          src="/mogelijk-logo-text.svg"
          alt="mogelijk vastgoed"
          width="160"
          class="top-0 ml-2 mt-2 hidden w-28 overflow-hidden tablet:inline"
        />
      </div>
    </header>
    <div class="flex h-full min-h-full flex-1 flex-col items-center">
      <slot />
    </div>
    <div class="w-full max-w-screen flex place-self-end jutify-self-end mt-2.5">
      <PageFooter class="justify-self-end" />
    </div>
  </main>
</template>
